import apiService from "./apiService";
import { ICard } from "../models";
import { IFreeWalletCardFetchData } from "@src/screens/freeWalletCard/CreateFreeWalletForm";

export type TUpdateCardParams = Omit<ICard, "_id">;

export default {
  registerPromoWalletCard: (
    data: IFreeWalletCardFetchData
  ): Promise<{ walletLink: string }> =>
    apiService.makeApiRequest(`api/v1/promo/walletcard`, {
      method: "POST",
      data,
    }),
};
