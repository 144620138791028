import styled from "@emotion/styled";
import React, { useState } from "react";
import StoreLink from "@components/StoreLink";
import Header from "@components/Header";
import Loading from "@components/Loading";
import CreateFreeWalletForm from "@src/screens/freeWalletCard/CreateFreeWalletForm";
import DownloadFreeWalletCardScreen from "@src/screens/freeWalletCard/DownloadFreeWalletCardScreen";
import promoService from "@services/promoService";
import { useAlert } from "react-alert";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  padding: 15px 16px;
  box-sizing: border-box;
`;

interface IData {
  name: string;
  phone: string;
  email: string;
  note?: string;
}

const Layout: React.FC = ({ children }) => (
  <>
    <StoreLink />
    <Root>
      <Header />
      {children}
    </Root>
  </>
);

const FreeWalletCardScreen: React.FC<IProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<any>(null);
  const alert = useAlert();

  const handleFetch = async (data: IData) => {
    setLoading(true);
    try {
      const res = await promoService.registerPromoWalletCard(data);
      setLink(res.walletLink);
    } catch (e: any) {
      alert.error(
        "Слишком много попыток. Ваше устройство заблокировано. Попробуйте завтра 😇"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      {loading && <Loading style={{ height: "unset" }} />}
      {!loading && !link && <CreateFreeWalletForm onFetch={handleFetch} />}
      {link && <DownloadFreeWalletCardScreen link={link} />}
    </Layout>
  );
};
export default FreeWalletCardScreen;
