import { firebaseService } from "@services/index";
import { action, computed, observable, runInAction } from "mobx";
import { RootStore } from "@stores/index";
import authService from "@services/authService";
import firebase from "firebase/app";
import { ROUTES } from "@stores/RouterStore";

export interface IUser {
  firebase_uid?: string;
  phone_number?: string;
  email?: string | null;
  isAnonymous: boolean;
}

export default class AccountStore {
  rootStore: RootStore;

  private _recaptchaVerifier = new firebaseService.firebase.auth.RecaptchaVerifier(
    "recaptcha-verifier",
    {
      size: "invisible",
      callback: (response: any) => {
        document.getElementById("recaptcha-verifier")!.style.visibility =
          "hidden";
      },
    }
  );

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    firebase.auth().languageCode = "ru";
    firebaseService.auth.onAuthStateChanged(
      this._onAuthStateChanged.bind(this)
    );
  }

  @observable initialized = false;
  @observable isCaptchaSent = false;
  @observable user: IUser | null = null;
  @observable userCardsIds: string[] = [];

  @computed
  get isLoggedIn() {
    return this.user != null && !this.user.isAnonymous;
  }

  @action public signOut = () => firebaseService.auth.signOut().then().catch();

  @action
  public signInWithEmailAndPassword = async (
    login: string,
    password: string
  ) => {
    const res = await firebaseService.auth
      .setPersistence(firebaseService.firebase.auth.Auth.Persistence.LOCAL)
      .then((_) =>
        firebaseService.auth.signInWithEmailAndPassword(login, password)
      );
    this.user = res.user;
  };
  @action
  public signInWithPhoneNumber = (phoneNumber: string) =>
    firebaseService.auth
      .setPersistence(firebaseService.firebase.auth.Auth.Persistence.LOCAL)
      .then((_) =>
        firebaseService.auth.signInWithPhoneNumber(
          phoneNumber,
          this._recaptchaVerifier
        )
      );

  @action
  public signInWithGoogle = () => {
    this.initialized = false;
    const googleProvider = new firebaseService.firebase.auth.GoogleAuthProvider();
    return firebaseService.auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        this.user = res.user;
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => (this.initialized = true));
  };

  @action
  public signInWithApple = () => {
    this.initialized = false;
    const appleProvider = new firebase.auth.OAuthProvider("apple.com");
    appleProvider.addScope("email");
    appleProvider.addScope("name");
    appleProvider.setCustomParameters({
      locale: "ru",
    });
    return firebaseService.auth
      .signInWithPopup(appleProvider)
      .then((res) => {
        console.log(res.user);
        this.user = res.user;
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => (this.initialized = true));
  };

  @action
  private async _onAuthStateChanged(user: firebase.User | null) {
    if (user == null) {
      this.user = null;
    } else {
      try {
        await user.getIdToken();
        const customer = await authService.auth();
        runInAction(() => {
          this.user = customer;
        });
      } catch (e: any) {
        await firebaseService.auth.signOut();
      }
    }
    this.initialized = true;
  }

  @action public sendSignInLinkToEmail = async (email: string, code: any) => {
    this.initialized = false;
    try {
      await firebase.auth().sendSignInLinkToEmail(email, code);
      window.localStorage.setItem("emailForSignIn", email);
    } catch (e: any) {
      console.error(e.message);
    } finally {
      this.initialized = true;
    }
  };
  @action finishRegisterOrSignUpWithEmail = async (register?: boolean) => {
    this.initialized = false;
    const searchParams = new URLSearchParams(window.location.search);
    let email =
      window.localStorage.getItem("emailForSignIn") ??
      searchParams.get("email");
    if (!email) {
      this.rootStore.errorStore.setError("Let's try different auth way");
      this.rootStore.routerStore.history.push(ROUTES.OOPS);
    }
    if (
      !email ||
      !firebaseService.auth.isSignInWithEmailLink(window.location.href)
    ) {
      this.rootStore.routerStore.history.push(ROUTES.OOPS);
      return;
    }
    try {
      const result = await firebaseService.auth.signInWithEmailLink(
        email,
        window.location.href
      );
      this.user = result.user;
      if (register) {
        const id = new URLSearchParams(window.location.search).get("cardId");
        if (id == null) {
          this.rootStore.routerStore.history.push(ROUTES.OOPS);
          return;
        }
        await this.rootStore.cardStore.registerCardById(id);
      }
      this.rootStore.routerStore.history.push(ROUTES.EDIT);
    } catch (e: any) {
      console.error(e.message);
      this.rootStore.errorStore.setError("Let's try different auth way");
      this.rootStore.routerStore.history.push(ROUTES.OOPS);
    } finally {
      this.initialized = true;
    }
  };
}
//todo
