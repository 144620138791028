import styled from "@emotion/styled";
import React, { useState } from "react";
import Title from "@components/fontComponents/Title";
import Subtitle from "@components/fontComponents/Subtitle";
import { TextArea } from "@components/Input";
import { useUpdateScreenVM } from "@src/screens/UpdateScreen/UpdateScreenVM";
import { useObserver } from "mobx-react-lite";
import { Row } from "@components/flex";
import ToggleArrow from "@components/ToggleArrow";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-bottom: 21px;
`;

const DescriptionTab: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const [opened, setOpened] = useState(true);
  const dictionary = useLanguage();
  return useObserver(() => (
    <Root>
      <Row
        onClick={() => setOpened(!opened)}
        style={{ marginBottom: 21, cursor: "pointer", userSelect: "none" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Title>{dictionary.textTab.title}</Title>
        <ToggleArrow opened={opened} />
      </Row>
      {opened && (
        <>
          <Subtitle style={{ padding: "12px 0 27px" }}>
            {dictionary.textTab.whatTextFor}
          </Subtitle>
          <TextArea
            value={vm.card.description}
            placeholder={dictionary.placeholders.anyText}
            onChange={(e) => (vm.card.description = e.target.value)}
          />
        </>
      )}
    </Root>
  ));
};
export default DescriptionTab;
