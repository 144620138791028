import React from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import UpdateCardScreen from "@src/screens/UpdateScreen";
import CardScreen from "@src/screens/card/CardScreen";
import PrivateRoute from "@components/PrivateRoute";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import Loading from "@components/Loading";
import PrivacyRulesScreen from "@src/screens/PrivacyRulesScreen";
import PhoneRegisterScreen from "@src/screens/auth/PhoneAuth/PhoneRegisterScreen";
import RegisterSplashScreen from "@src/screens/auth/RegisterSplashScreen";
import LoginSplashScreen from "@src/screens/auth/LoginSplashScreen";
import PhoneLoginScreen from "@src/screens/auth/PhoneAuth/PhoneLoginScreen";
import EmailRegisterScreen from "./screens/auth/EmailAuth/EmailRegisterScreen";
import EmailLoginScreen from "@src/screens/auth/EmailAuth/EmailLoginScreen";
import BadScreen from "./screens/result/BadScreen";
import MarketplaceScreen from "@src/screens/marketplace/MarketplaceScreen";
import FailedPayment from "./screens/result/FailedPayment";
import SucceededPayment from "./screens/result/SucceededPayment";
import FreeWalletCardScreen from "@src/screens/freeWalletCard/FreeWalletCardScreen";

interface IProps {}

const App: React.FunctionComponent<IProps> = () => {
  const rootStore = useStores();
  const initialized = useObserver(() => rootStore.initialized);
  if (!initialized) return <Loading />;
  return (
    <Switch>
      <Route path={ROUTES.ROOT} exact>
        <LoginSplashScreen />
      </Route>
      <Route path={ROUTES.CARD} exact>
        <CardScreen />
      </Route>

      <Route path={ROUTES.FREE_WALLET_CARD} exact>
        <FreeWalletCardScreen />
      </Route>

      <Route path={ROUTES.OOPS} exact>
        <BadScreen />
      </Route>
      <Route path={ROUTES.FAILED_PAYMENT} exact>
        <FailedPayment />
      </Route>
      <Route path={ROUTES.SUCCEEDED_PAYMENT} exact>
        <SucceededPayment />
      </Route>

      <Route path={ROUTES.PRIVACY} exact>
        <PrivacyRulesScreen />
      </Route>

      {/* register root*/}
      <Route path={ROUTES.REGISTER} exact>
        <RegisterSplashScreen />
      </Route>
      {/* register phone*/}
      <Route path={ROUTES.REGISTER_PHONE} exact>
        <PhoneRegisterScreen />
      </Route>
      <Route path={ROUTES.REGISTER_EMAIl} exact>
        <EmailRegisterScreen />
      </Route>

      {/* login root*/}
      <Route path={ROUTES.LOGIN} exact>
        <LoginSplashScreen />
      </Route>
      {/* login phone*/}
      <Route path={ROUTES.LOGIN_PHONE} exact>
        <PhoneLoginScreen />
      </Route>
      <Route path={ROUTES.LOGIN_EMAIl} exact>
        <EmailLoginScreen />
      </Route>

      <PrivateRoute path={ROUTES.EDIT} exact>
        <UpdateCardScreen />
      </PrivateRoute>

      {/*Marketplace*/}
      <Route path={ROUTES.MARKETPLACE} exact>
        <MarketplaceScreen />
      </Route>
    </Switch>
  );
};

export default App;
