import styled from "@emotion/styled";
import React, { useState } from "react";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { Row } from "@components/flex";
import Title from "@components/fontComponents/Title";
import ToggleArrow from "@components/ToggleArrow";
import Subtitle from "@components/fontComponents/Subtitle";
import { useUpdateScreenVM } from "@src/screens/UpdateScreen/UpdateScreenVM";
import Button from "@components/Button";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
`;

const DeleteCard: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const history = useHistory();
  const dictionary = useLanguage();
  const phrases = Object.values(dictionary.deleteAccTab.phrases);
  const { accountStore } = useStores();
  const [opened, setOpened] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const handleDeletion = async () => {
    await vm.deleteAccount();
    await accountStore.signOut();
    history.push(ROUTES.ROOT);
    window.location.reload();
  };
  return useObserver(() => (
    <Root>
      <Row
        onClick={() => setOpened(!opened)}
        style={{ marginBottom: 12, cursor: "pointer", userSelect: "none" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Title>{dictionary.deleteAccTab.title}</Title>
        <ToggleArrow opened={opened} />
      </Row>
      {opened && (
        <>
          <Subtitle>{dictionary.deleteAccTab.description}</Subtitle>
          <Button
            style={{ backgroundColor: "#FF0000", marginTop: 12 }}
            onClick={() =>
              index === phrases.length - 1
                ? handleDeletion()
                : setIndex(index + 1)
            }
          >
            {phrases[index]}
          </Button>
        </>
      )}
    </Root>
  ));
};

export default DeleteCard;
