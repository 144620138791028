import React from "react";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import EmailAuth from "@src/screens/auth/EmailAuth/EmailAuth";

interface IProps {}

const EmailLoginScreen: React.FC<IProps> = () => {
  const { accountStore } = useStores();
  const isLoggedIn = useObserver(() => accountStore.isLoggedIn);
  if (isLoggedIn) return <Redirect to={ROUTES.ROOT} />;
  return <EmailAuth />;
};
export default EmailLoginScreen;
