import React from "react";
import styled from "@emotion/styled";

const Root = styled.div`
  display: flex;
  position: absolute;
  left: calc(50% - 26px);
  bottom: -26px;
`;

const CameraIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <Root>
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.4414" cy="26.542" r="26.542" fill="white" />
      <circle
        cx="27.4409"
        cy="26.5419"
        r="22.979"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4787 25.2945C26.6732 25.2945 25.9172 25.5953 25.3348 26.161C24.7647 26.7146 24.4549 27.4488 24.4673 28.207V28.219C24.4673 29.0013 24.7771 29.7354 25.3472 30.2891C25.9172 30.8427 26.6732 31.1436 27.4787 31.1436C29.1394 31.1436 30.4778 29.8317 30.4902 28.219C30.4902 27.4367 30.1804 26.7026 29.6103 26.149C29.0402 25.5953 28.2843 25.2945 27.4787 25.2945ZM34.4308 25.3783C33.8112 25.3783 33.3155 24.8969 33.3155 24.2952C33.3155 23.6934 33.8112 23.2 34.4308 23.2C35.0505 23.2 35.5586 23.6934 35.5586 24.2952C35.5586 24.8969 35.0505 25.3783 34.4308 25.3783ZM30.9111 31.5646C30.0312 32.4191 28.8167 32.9486 27.4783 32.9486C26.1771 32.9486 24.9626 32.4552 24.0331 31.5646C23.116 30.6619 22.6079 29.4825 22.6079 28.2188C22.5955 26.9671 23.1036 25.7877 24.0207 24.885C24.9502 23.9824 26.1771 23.489 27.4783 23.489C28.7795 23.489 30.0064 23.9824 30.9235 24.873C31.8406 25.7757 32.3487 26.9671 32.3487 28.2188C32.3363 29.5306 31.791 30.7101 30.9111 31.5646ZM34.4683 19.8182C34.3568 19.8182 34.27 19.7581 34.2204 19.6738L34.0965 19.409C33.7619 18.723 33.3777 17.9287 33.1423 17.4714C32.5722 16.3882 31.5932 15.7864 30.3911 15.7744H24.554C23.3519 15.7864 22.3853 16.3882 21.8152 17.4714C21.5674 17.9528 21.146 18.8193 20.799 19.5294L20.7247 19.6738C20.6875 19.7701 20.5883 19.8182 20.4892 19.8182C17.5025 19.8182 15.0859 22.1771 15.0859 25.0656V32.2145C15.0859 35.1029 17.5025 37.4618 20.4892 37.4618H34.4683C37.4426 37.4618 39.8716 35.1029 39.8716 32.2145V25.0656C39.8716 22.1771 37.4426 19.8182 34.4683 19.8182Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  </Root>
);

export default CameraIcon;
// position="absolute"
