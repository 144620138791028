import apiService from "./apiService";

export interface ITouchData {
  cardId: string;
  location?: { latitude: number; longitude: number };
}

export default {
  touch: (data: ITouchData) =>
    apiService.makeApiRequest(`api/v1/touches/touch/`, {
      method: "POST",
      data,
    }),
  count: (cardId: string): Promise<{ count: number }> =>
    apiService.makeApiRequest(`api/v1/touches/count/${cardId}`),
};
