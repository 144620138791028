import React from "react";
import ResultScreen, {
  RESULT_SCREEN_TYPE,
} from "@src/screens/result/ResultScreen";

interface IProps {}

const FailedPayment: React.FC<IProps> = () => {
  return (
    <ResultScreen
      title="Проблемка"
      subtitle="Проблемы с оплатой"
      btnText="В магазин"
      onButtonClick={() =>
        window.location.replace("https://kasanie.org/#shop")
      }
      type={RESULT_SCREEN_TYPE.ERROR}
    />
  );
};
export default FailedPayment;
