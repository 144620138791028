import React from "react";
import { useVM } from "@src/hooks/useVM";
import { action, computed, observable, runInAction } from "mobx";
import marketService from "@services/marketService";
import { ICreateOrderInfo, IProduct, IPromocode } from "@src/models";

const ctx = React.createContext<MarketplaceScreenVM | null>(null);

export const MarketplaceScreenVMProvider: React.FC = ({ children }) => {
  const store = new MarketplaceScreenVM();
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};
export const useMarketplaceScreenVM = () => useVM(ctx);

export type TSelectItemData = { key: string; value: string };

interface IOrderInfo {
  name?: string;
  phone?: string;
  address?: string;
  email?: string;
  promocode?: string;
  count: number;
}

export const typeDescriptionMap: Record<string, string> = {
  Плоский:
    "\n - Размеры: 32 x 32 x 0.38 мм\n - Тип визитки: Плоская\n\n Эта визитка предназначена для ношения под чехлом телефона.",
  Выпуклый:
    "\n - Размеры: 32 x 32 x 1.88 мм\n - Тип визитки: Выпуклая\n\n Эта визитка предназначена для ношения на телефоне или любой другой поверхности.",
};

class MarketplaceScreenVM {
  constructor() {
    this.sync().then();
  }

  @observable orderInfo: IOrderInfo = {
    name: "",
    phone: "",
    address: "",
    email: "",
    promocode: "",
    count: 1,
  };
  @observable products: IProduct[] = [];
  @observable initializing: boolean = true;
  @observable promocode: IPromocode | null = null;

  @observable selectedProductId: string | null = null;

  @computed get selectedProduct() {
    const product = this.products.find(
      ({ id }) => id === this.selectedProductId
    );
    const result = product != null ? { ...product } : undefined;
    if (
      result != null &&
      this.promocode != null &&
      this.promocode.products.includes(result.id)
    ) {
      result.price = Math.ceil(result.price * (1 - this.promocode.discount));
    }
    return result;
  }

  @computed get categories() {
    return this.products.reduce(
      (acc, v) => ({
        ...acc,
        [v.category]: Array.isArray(acc[v.category])
          ? [...acc[v.category], v]
          : [v],
      }),
      {} as Record<string, IProduct[]>
    );
  }

  @action sync = async () => {
    const products = await marketService.products();
    runInAction(() => {
      this.products = products;
      this.initializing = false;
    });
  };

  @action checkPromocode = async (): Promise<{
    success: boolean;
    message: string;
    name?: string;
  }> =>
    marketService
      .checkPromocode({ name: this.orderInfo.promocode ?? "" })
      .then((p) => {
        this.promocode = p;
        return {
          success: true,
          message: `Промокод ${p.name} успешно применен!`,
          name: p.name,
        };
      })
      .catch((e) => {
        this.promocode = null;
        return {
          success: false,
          message: `Промокод недействителен или его не существует :с`,
        };
      });

  createOrder = async () => {
    const selectedProductId = this.selectedProduct?.id;
    if (selectedProductId == null) return;
    const data: ICreateOrderInfo = {
      name: this.orderInfo.name,
      address: this.orderInfo.address,
      email: this.orderInfo.email,
      phone: this.orderInfo.phone,
      promocodeId: this.promocode?.id,
      items: [
        {
          id: selectedProductId,
          count: this.orderInfo.count,
        },
      ],
    };
    const res = await marketService.createOrder(data);
    return res.paymentLink;
  };
}
