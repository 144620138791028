import React, { useState } from "react";
import { Column } from "@components/flex";
import Title from "@components/fontComponents/Title";
import SizedBox from "@components/SizedBox";
import Subtitle from "@components/fontComponents/Subtitle";
import Input from "@components/Input";
import Button from "@components/Button";

export interface IFreeWalletCardFetchData {
  name: string;
  phone: string;
  email: string;
}

interface IProps {
  onFetch: (data: IFreeWalletCardFetchData) => any;
}

const CreateFreeWalletForm: React.FC<IProps> = ({ onFetch }) => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  return (
    <Column crossAxisSize="max">
      <Title>Создание wallet визитки</Title>
      <SizedBox height={12} />
      <Subtitle>
        Заполните данные и нажмите “Продолжить” для того чтобы добавить визитку
        себе в wallet
      </Subtitle>
      <SizedBox height={24} />
      <Input
        value={name}
        onChange={setName}
        placeholder="Ваше имя*"
        maxLength={50}
      />
      <SizedBox height={12} />
      <Input
        inputType="phone"
        value={phone}
        onChange={setPhone}
        placeholder="Ваш телефон*"
      />
      <SizedBox height={12} />
      <Input value={email} onChange={setEmail} placeholder="Ваша почта*" />
      <SizedBox height={64} />
      <Button
        disabled={!name || !phone || !email}
        onClick={() => onFetch({ name, phone, email })}
      >
        Продолжить
      </Button>
      <SizedBox height={42} />
    </Column>
  );
};
export default CreateFreeWalletForm;
