import React from "react";
import { Column } from "@components/flex";
import img from "@src/assets/okFace.svg";
import Title from "@components/fontComponents/Title";
import { ButtonLink } from "@components/Button";
import SizedBox from "@components/SizedBox";
import TextButton from "@components/TextButton";

export interface IFreeWalletCardFetchData {
  name?: string;
  phone?: string;
  email?: string;
  text?: string;
}

interface IProps {
  link: string;
}

const DownloadFreeWalletCardScreen: React.FC<IProps> = ({ link }) => {
  return (
    <Column crossAxisSize="max" justifyContent={"center"} alignItems={"center"}>
      <SizedBox height={48} />
      <img src={img} alt="success" style={{ width: 180 }} />
      <SizedBox height={24} />
      <Title style={{ textAlign: "center" }}>
        Ваша wallet визитка успешно создана!
      </Title>
      <SizedBox height={24} />
      <ButtonLink onClick={() => window.open(link)}>
        Добавить в Wallet
      </ButtonLink>
      <SizedBox height={128} />
      <TextButton
        onClick={() =>
          window.open("https://kasanie.org/")
        }
      >
        Заказать касание
      </TextButton>
    </Column>
  );
};
export default DownloadFreeWalletCardScreen;
