import React from "react";
import ResultScreen, {
  RESULT_SCREEN_TYPE,
} from "@src/screens/result/ResultScreen";
import { useObserver } from "mobx-react-lite";
import { useStores } from "@stores";

interface IProps {}

const BadScreen: React.FC<IProps> = () => {
  const { errorStore } = useStores();
  const error = useObserver(() => errorStore.message);
  const switchText = () => {
    switch (error) {
      case "There is no card for this user":
        return "Для того чтобы начать пользоваться нашим сервисом в полной мере, Вам необходимо купить nfc-метку";
      case "There is no card with this id":
      case "There is no card":
        return "Такой визитки нет, проверьте корректна ли ваша ссылка";
      case "Let's try different auth way":
        return "Попробуйте другой метод авторизации";
      default:
        return "Что-то пошло не так";
    }
  };
  return (
    <ResultScreen
      title="Проблемка"
      subtitle={switchText()}
      btnText="В магазин"
      onButtonClick={() =>
        window.location.replace("https://kasanie.org/#shop")
      }
      type={RESULT_SCREEN_TYPE.ERROR}
    />
  );
};
export default BadScreen;
