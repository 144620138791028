import styled from "@emotion/styled";
import React, { useState } from "react";
import Button, { ButtonLink } from "@components/Button";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import qr from "@src/assets/qr.svg";
import { isIOS, isMacOs } from "react-device-detect";

import Modal from "@src/Modal";
import { Column } from "@components/flex";
import QRCode from "qrcode.react";
import { getLinkById } from "@utils";
import useLanguage from "@src/hooks/useLanguage";

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;
const SaveEditBtn: React.FC<IProps> = ({ ...props }) => {
  const { accountStore, cardStore } = useStores();
  const history = useHistory();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const dictionary = useLanguage();
  return useObserver(() => (
    <Root {...props}>
      {accountStore.user?.firebase_uid === cardStore.card.userId ? (
        <>
          <Row>
            <ButtonLink
              style={{ margin: "21px 8px 21px 0" }}
              onClick={() => history.push(ROUTES.EDIT)}
            >
              {dictionary.edit}
            </ButtonLink>
            {isIOS || isMacOs ? (
              <ButtonLink
                style={{ maxWidth: 48 }}
                href={cardStore.getWalletLink()}
              >
                <img src={qr} alt="qr" />
              </ButtonLink>
            ) : (
              <Button
                style={{ width: 48 }}
                onClick={() => setModalOpened(true)}
              >
                <img src={qr} alt="qr" />
              </Button>
            )}
          </Row>
          {modalOpened && (
            <Modal
              title={dictionary.shareYourCard}
              onClose={() => setModalOpened(false)}
            >
              <Column
                style={{ width: "100%", height: "100%" }}
                alignItems="center"
                justifyContent="center"
              >
                <QRCode
                  style={{ width: "70%", height: "auto" }}
                  value={getLinkById(cardStore.card.id)}
                />
              </Column>
            </Modal>
          )}
        </>
      ) : (
        <ButtonLink
          href={cardStore.getContactLink()}
          style={{ margin: "21px 8px 21px 0" }}
        >
          {dictionary.saveToPhone}
        </ButtonLink>
      )}
    </Root>
  ));
};
export default SaveEditBtn;
