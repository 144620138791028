import styled from "@emotion/styled";
import React, { useState } from "react";
import Input from "@components/Input";
import AddButton from "@components/AddButton";
import { useUpdateScreenVM } from "@src/screens/UpdateScreen/UpdateScreenVM";
import { useObserver } from "mobx-react-lite";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

type TAutofocusValue = {
  type: "phone" | "mail";
  index: number;
};

const PhonesEmailsTab: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const dictionary = useLanguage();
  const [autofocus, setAutofocus] = useState<TAutofocusValue | null>(null);

  const handleAddPhone = () => {
    vm.card.phones?.push("");
    setAutofocus({ type: "phone", index: vm.card.phones!.length - 1 });
  };

  const handleAddEmail = () => {
    vm.card.emails?.push("");
    setAutofocus({ type: "mail", index: vm.card.emails!.length - 1 });
  };

  return useObserver(() => (
    <Root>
      {(vm.card.phones ?? []).map((phone, index) => (
        <Input
          autoFocus={
            autofocus != null &&
            autofocus.type === "phone" &&
            autofocus.index === index
          }
          key={index}
          onFocus={() => setAutofocus(null)}
          inputType="phone"
          value={phone}
          onChange={(v: string) => ((vm.card.phones as string[])[index] = v)}
          placeholder={dictionary.placeholders.phone}
          onTrashClick={() => vm.card.phones?.splice(index, 1)}
          style={{ marginTop: 11 }}
        />
      ))}
      <AddButton onClick={handleAddPhone}>
        {dictionary.mainInfoTab.addPhone}
      </AddButton>

      {(vm.card.emails ?? []).map((email, index) => (
        <div style={{ marginTop: 11 }} key={index}>
          <Input
            autoFocus={
              autofocus != null &&
              autofocus.type === "mail" &&
              autofocus.index === index
            }
            key={index}
            value={email}
            placeholder={dictionary.placeholders.email}
            onChange={(v) => ((vm.card.emails as string[])[index] = v)}
            onTrashClick={() => vm.card.emails?.splice(index, 1)}
          />
        </div>
      ))}
      <AddButton onClick={handleAddEmail}>
        {dictionary.mainInfoTab.addEmail}
      </AddButton>
    </Root>
  ));
};
export default PhonesEmailsTab;
