import styled from "@emotion/styled";
import React from "react";
import Title from "@components/fontComponents/Title";
import { ISocialLink } from "@src/models";
import LinkComponent from "./LinkComponent";
import useLanguage from "@src/hooks/useLanguage";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  paymentLinks?: ISocialLink[];
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  margin: -4px;
  box-sizing: border-box;
`;
const Links = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

const PaymentTab: React.FC<IProps> = ({ paymentLinks, ...rest }) => {
  const dictionary = useLanguage();
  if (paymentLinks?.length === 0) return null;
  return (
    <Root {...rest}>
      <Title style={{ paddingBottom: 20, paddingTop: 30 }}>
        {dictionary.moneyTab.title}
      </Title>
      <Body>
        <Links>
          {paymentLinks != null &&
            paymentLinks.map((link, index) => (
              <LinkComponent
                {...link}
                key={index}
                textColor="545961"
                bordered
              />
            ))}
        </Links>
      </Body>
    </Root>
  );
};
export default PaymentTab;
