import styled from "@emotion/styled";
import React from "react";
import Header from "@components/Header";
import { layoutStyle } from "@components/Layout";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layoutStyle};
`;

const Text = styled.p`
	max-width: 440px;
	font-size: 16px;
	line-height: 1.4em;
`;

const Link = styled.a`
	text-decoration: none;
	font-size: 16px;
	color: #2934d0;
`;

const PhoneAuthUnavailable: React.FC = () => {
	return <Root>
		<Header style={{ marginBottom: 82 }} />
		<Text>
			Сейчас авторизация через телефон не поддерживается. 
			Если у вас уже был аккаунт, привязанный к телефонному номеру – <Link href="https://t.me/kasanie_info" target="_blank">обратитесь в поддержку.</Link>
		</Text>
	</Root>
}

export default PhoneAuthUnavailable;