import styled from "@emotion/styled";
import React, { useEffect } from "react";
import {
  UpdateScreenVMProvider,
  useUpdateScreenVM,
} from "@src/screens/UpdateScreen/UpdateScreenVM";
import { useObserver } from "mobx-react-lite";
import ImageUpload from "@src/screens/UpdateScreen/ImageUpload";
import Button from "@components/Button";
import Loading from "@components/Loading";
import Title from "@components/fontComponents/Title";
import Subtitle from "@components/fontComponents/Subtitle";
import SocialLinksTab from "@src/screens/UpdateScreen/SocialLinksTab";
import FIO from "./FIO";
import PhonesEmailsTab from "@src/screens/UpdateScreen/PhonesEmailsTab";
import WorkTab from "./WorkTab";
import DescriptionTab from "@src/screens/UpdateScreen/DescriptionTab";
import Layout, { layoutStyle } from "@components/Layout";
import { useAlert } from "react-alert";
import { cardService } from "@services";
import Payments from "@src/screens/UpdateScreen/Payments";
import DeleteCard from "./DeleteCard";
import UserNameTab from "@src/screens/UpdateScreen/UserNameTab";
import HelpButton from "@components/HelpButton";
import useLanguage from "@src/hooks/useLanguage";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const UpdateScreen: React.FC<IProps> = () => {
  return (
    <UpdateScreenVMProvider>
      <UpdateScreenImpl />
    </UpdateScreenVMProvider>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;

  .cardBody {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 60px;
    ${layoutStyle};
  }
`;

const FixedBlock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );

  .layout {
    width: 100%;
    margin: 20px 0;
  }
`;

const UpdateScreenImpl: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const alert = useAlert();
  const dictionary = useLanguage();
  useEffect(() => {
    if (vm.card.emails?.length === 0) vm.card.emails.push("");
    if (vm.card.phones?.length === 0) vm.card.phones.push("");
  });
  const handleUpdate = () =>
    vm.updateCard().catch((e) => {
      console.error(e);
      alert.error(e.toString());
    });

  return useObserver(() => {
    if (!vm.initialized) return <Loading />;
    return (
      <Root>
        <ImageUpload
          image={vm.card.photo}
          onChange={async (photo: File) =>
            (vm.card.photo = await cardService.uploadPhoto(photo))
          }
          loading={vm.photoLoading}
          setLoading={(loading) => (vm.photoLoading = loading)}
        />
        <div className="cardBody">
          <Title style={{ paddingTop: 46 }}>
            {dictionary.mainInfoTab.fillInfo}
          </Title>
          <Subtitle style={{ padding: "11px 0" }}>
            {dictionary.mainInfoTab.fillInfoToDisplay}
          </Subtitle>
          <FIO />
          <PhonesEmailsTab />
          <UserNameTab />
          <SocialLinksTab />
          <Payments />
          <WorkTab />
          <DescriptionTab />
          <DeleteCard />
          <FixedBlock>
            <Layout className="layout">
              <Button disabled={vm.saveDisabled} onClick={handleUpdate}>
                {dictionary.save}
              </Button>
              <HelpButton />
            </Layout>
          </FixedBlock>
        </div>
      </Root>
    );
  });
};

export default UpdateScreen;
