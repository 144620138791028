import React, { HTMLAttributes } from "react";
import styled from "@emotion/styled";
import Subtitle2 from "@components/fontComponents/Subtitle2";
import SizedBox from "@components/SizedBox";
import Subtitle from "@components/fontComponents/Subtitle";
import { IProduct } from "@src/models";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  product: IProduct;
  avatarSize?: number;
  count?: number;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.div<{ url: string; size?: number }>`
  background: url(${({ url }) => url}) center no-repeat;
  background-size: cover;
  width: ${({ size }) => size ?? 100}px;
  height: ${({ size }) => size ?? 100}px;
  border-radius: 10px;
`;

const Product: React.FC<IProps> = ({
  product,
  avatarSize,
  count = 1,
  ...rest
}) => (
  <Root {...rest}>
    <Image size={avatarSize} className="avatar" url={product.picture} />
    <SizedBox height={8} />
    <Subtitle>{product.price * count} руб.</Subtitle>
    {product.oldPrice != null && (
      <Subtitle2 style={{ textDecoration: "line-through" }}>
        {product.oldPrice * count} руб.
      </Subtitle2>
    )}
  </Root>
);

export default Product;
