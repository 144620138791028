import {
  RouterStore,
  CardStore,
  AccountStore,
  TouchesStore,
  ErrorStore,
} from "./index";
import { computed } from "mobx";
import SettingsStore from "@stores/SettingsStore";

export default class RootStore {
  public accountStore: AccountStore;
  public routerStore: RouterStore;
  public cardStore: CardStore;
  public touchesStore: TouchesStore;
  public errorStore: ErrorStore;
  public settingsStore: SettingsStore;

  constructor() {
    this.accountStore = new AccountStore(this);
    this.routerStore = new RouterStore(this);
    this.cardStore = new CardStore(this);
    this.touchesStore = new TouchesStore(this);
    this.errorStore = new ErrorStore(this);
    this.settingsStore = new SettingsStore(this);

    Promise.all([this.cardStore.sync(), this.touchesStore.sync()]).then();
  }

  @computed get initialized() {
    return this.accountStore.initialized && this.cardStore.initialized;
  }
}
