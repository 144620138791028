import { RootStore } from "./index";
import { createBrowserHistory, Location } from "history";
import { observable, runInAction, when } from "mobx";
import { matchPath } from "react-router-dom";

export enum ROUTES {
  ROOT = "/",
  REGISTER = "/register/:cardId",
  REGISTER_PHONE = "/register/phone/:cardId",
  REGISTER_EMAIl = "/register/email/:cardId",
  EDIT = "/edit",
  CARD = "/card/:cardId",
  LOGIN = "/login",
  LOGIN_PHONE = "/login/phone",
  LOGIN_EMAIl = "/login/email",
  OOPS = "/oops",
  FAILED_PAYMENT = "/failedpayment",
  SUCCEEDED_PAYMENT = "/succeededpayment",
  PAGE404 = "/ooops",
  PRIVACY = "/privacy",
  FINISH_SIGN_UP = "/finishSignUp",
  FINISH_REGISTER = "/finishRegistration",
  MARKETPLACE = "/marketplace",
  FREE_WALLET_CARD = "/walletcard",
}

export default class RouterStore {
  public rootStore: RootStore;
  public history = createBrowserHistory();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.history.listen((location, action) => {
      this.sync(location);
    });
    this.sync(this.history.location);
    when(
      () =>
        this.currentPath === ROUTES.FINISH_SIGN_UP ||
        this.currentPath === ROUTES.FINISH_REGISTER,
      () => {
        this.rootStore.accountStore
          .finishRegisterOrSignUpWithEmail(
            this.currentPath === ROUTES.FINISH_REGISTER
          )
          .then();
      }
    );
  }

  @observable
  currentPath: ROUTES = ROUTES.ROOT;

  @observable
  searchParams = new URLSearchParams();

  getCardId(pathRaw: string | Array<string>) {
    const go = (path: string) =>
      matchPath<{ cardId: string }>(this.rootStore.routerStore.currentPath, {
        path,
        exact: true,
      })?.params?.cardId;
    return (typeof pathRaw === "string" ? [pathRaw] : pathRaw).reduce(
      (acc, v) => {
        const res = go(v);
        return res != null ? res : acc;
      },
      null as string | null
    );
  }

  sync = (location: Location) => {
    runInAction(() => {
      this.currentPath = location.pathname as any;
      this.searchParams = new URLSearchParams(location.search);
    });
  };
}
