import styled from "@emotion/styled";
import React, { useState } from "react";
import Title from "@components/fontComponents/Title";
import Input from "@components/Input";
import { useUpdateScreenVM } from "@src/screens/UpdateScreen/UpdateScreenVM";
import { useObserver } from "mobx-react-lite";
import { Row } from "@components/flex";
import ToggleArrow from "@components/ToggleArrow";
import { SOCIAL_ICONS } from "@src/assets/socialIconsConfig";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  margin: -4px 0;

  & > * {
    margin: 4px 0;
  }
`;

const WorkTab: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const dictionary = useLanguage();
  const [opened, setOpened] = useState(true);
  return useObserver(() => (
    <Root>
      <Row
        onClick={() => setOpened(!opened)}
        style={{ marginBottom: 21, cursor: "pointer", userSelect: "none" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Title>{dictionary.workTab.title}</Title>
        <ToggleArrow opened={opened} />
      </Row>
      {opened && (
        <>
          <Input
            value={vm.card.workCompany}
            placeholder={dictionary.placeholders.companyName}
            onChange={(v) => (vm.card.workCompany = v)}
          />
          <Input
            value={vm.card.position}
            placeholder={dictionary.placeholders.position}
            onChange={(v) => (vm.card.position = v)}
          />
          <Input
            inputType="phone"
            value={vm.card.workPhone}
            onChange={(phone: string) => (vm.card.workPhone = phone)}
            placeholder={dictionary.placeholders.workPhone}
          />
          <Input
            value={vm.card.workEmail}
            placeholder={dictionary.placeholders.workEmail}
            onChange={(v) => (vm.card.workEmail = v)}
          />
          <Input
            value={vm.card.workAddress}
            placeholder={dictionary.placeholders.address}
            onChange={(v) => (vm.card.workAddress = v)}
          />
          <Input
            placeholder="https://www.linkedin.com/in/"
            value={vm.card.linkedIn}
            onCrossClick={() => (vm.card.linkedIn = "")}
            icon={SOCIAL_ICONS["linkedin"]}
            onChange={(v) => (vm.card.linkedIn = v)}
            inputType="link"
          />
          <Input
            placeholder={dictionary.placeholders.workSite}
            value={vm.card.workSite}
            onCrossClick={() => (vm.card.workSite = "")}
            icon={SOCIAL_ICONS["workSite"]}
            onChange={(v) => (vm.card.workSite = v)}
            inputType="link"
          />
        </>
      )}
    </Root>
  ));
};
export default WorkTab;
