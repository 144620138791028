import React from "react";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import EmailAuth from "./EmailAuth";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const EmailRegisterScreen: React.FC<IProps> = () => {
  const { accountStore, cardStore } = useStores();
  const isLoggedIn = useObserver(() => accountStore.isLoggedIn);
  const card = useObserver(() => cardStore.card);
  const dictionary = useLanguage();
  if (isLoggedIn || card.userId)
    return <Redirect to={ROUTES.CARD.replace(":cardId", card.id)} />;
  return (
    <EmailAuth
      register
      title={dictionary.register}
      subtitle={dictionary.registerToActivate}
    />
  );
};
export default EmailRegisterScreen;
