import { RootStore } from "./index";
import { action, computed, observable } from "mobx";
import { cardService } from "../services";
import { ICard } from "@src/models";
import { ROUTES } from "@stores/RouterStore";
import { getLinkById } from "@utils";

export default class CardStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable initialized = false;
  @observable card: ICard = {} as ICard;
  @observable direct: string = "";

  @action sync = async () => {
    let id = this.rootStore.routerStore.getCardId([
      ROUTES.CARD,
      ROUTES.REGISTER_EMAIl,
      ROUTES.REGISTER_PHONE,
      ROUTES.REGISTER,
    ]);
    if (id != null) {
      try {
        this.card = await cardService.card(id);
      } catch (e: any) {
        this.rootStore.errorStore.setError(e.message);
        this.rootStore.routerStore.history.push(ROUTES.OOPS);
      }
    }
    this.initialized = true;
  };

  @action registerCard = async (data?: { phone: string }) =>
    cardService.registerCard(this.card.id, data);

  @action registerCardById = async (id: string) => {
    if (id.length === 24) await cardService.registerCard(id);
  };

  @action getCardById = async (id: string) => {
    try {
      this.card = await cardService.card(id);
    } catch (e: any) {
      this.rootStore.errorStore.setError(e.message);
      this.rootStore.routerStore.history.push(ROUTES.ROOT);
    }
    this.initialized = true;
  };
  @action setDirect = async (direct?: string) => {
    try {
      this.card = await cardService.setDirect({ direct });
    } catch (e: any) {
      console.error(e);
    }
  };

  @computed get selfLink() {
    return getLinkById(this.card.id);
  }

  @computed get isCardTaken() {
    return !!this.card.userId;
  }

  getContactLink = () => {
    return window._env_.API_BASE + "/api/v1/cards/contact/" + this.card.id;
  };
  getWalletLink = () => {
    return window._env_.API_BASE + "/api/v1/cards/wallet/" + this.card.id;
  };
}
