import styled from "@emotion/styled";
import React from "react";
import Title from "@components/fontComponents/Title";
import FieldsDescription from "@components/fontComponents/FieldsDescription";
import FieldValue from "@components/fontComponents/FieldValue";
import { Column } from "@components/flex";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import LinkComponent from "@src/screens/card/LinkComponent";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  workCompany?: string;
  position?: string;
  workPhone?: string;
  workEmail?: string;
  workSite?: string;
  linkedIn?: string;
  workAddress?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  & > * {
    padding: 1px 0;
  }
`;
const Links = styled.div`
  display: flex;
  padding-top: 25px;
  & > :first-of-type {
    margin-left: 0px;
  }
  & > :last-child {
    margin-right: 0px;
  }
`;

const OutputRow: React.FC<{
  title: string;
  data?: string;
  onClick?: () => void;
}> = ({ title, data, onClick }) =>
  data ? (
    <Column onClick={onClick}>
      <FieldsDescription>{title}</FieldsDescription>
      <FieldValue>{data}</FieldValue>
    </Column>
  ) : null;

const WorkInfo: React.FC<IProps> = ({
  workCompany,
  position,
  workPhone,
  workEmail,
  workSite,
  linkedIn,
  workAddress,
}) => {
  const dictionary = useLanguage();
  return [
    workCompany,
    position,
    workPhone,
    workEmail,
    workSite,
    linkedIn,
    workAddress,
  ].some((v) => v != null && v !== "") ? (
    <Root>
      <Title style={{ paddingBottom: 20 }}>{dictionary.job}</Title>
      <OutputRow title={dictionary.company} data={workCompany} />
      <OutputRow title={dictionary.position} data={position} />
      <OutputRow
        onClick={() => window.location.replace(`tel:${workPhone}`)}
        title={dictionary.placeholders.workPhone}
        data={formatPhoneNumberIntl(workPhone ?? "")}
      />
      <OutputRow
        onClick={() => window.location.replace(`mailto:${workEmail}`)}
        title={dictionary.placeholders.workEmail}
        data={workEmail}
      />
      <OutputRow title={dictionary.placeholders.address} data={workAddress} />
      <Links>
        {workSite && (
          <LinkComponent bordered={true} type="workSite" value={workSite} />
        )}
        {linkedIn && (
          <LinkComponent bordered={true} type="linkedin" value={linkedIn} />
        )}
      </Links>
    </Root>
  ) : null;
};
export default WorkInfo;
