import styled from "@emotion/styled";
import React from "react";
import { Row } from "@components/flex";
import { ReactComponent as Back } from "@src/assets/Arrow Back.svg";
import { ReactComponent as Close } from "@src/assets/Close.svg";
import { layoutStyle } from "@components/Layout";
import { PAYMENT_NAMES, SOCIAL_NAMES } from "@src/assets/socialIconsConfig";
import Title from "@components/fontComponents/Title";
import LinkOutput from "./LinkOutput";

interface IProps extends Omit<React.HTMLProps<HTMLDivElement>, "onSelect"> {
  onSelect: (link: string) => void;
  goBack: () => void;
  closeDialog: () => void;
  isPaymentLink: boolean;
  title?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${layoutStyle}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;
const LinksTypeSelector: React.FC<IProps> = ({
  onSelect,
  closeDialog,
  goBack,
  isPaymentLink,
  title,
  ...rest
}) => (
  <Root {...rest}>
    <Row
      justifyContent="space-between"
      alignItems="center"
      style={{ paddingBottom: 23 }}
    >
      {title ? <Title>{title}</Title> : <Back onClick={goBack} />}
      <Close onClick={closeDialog} />
    </Row>

    <Body>
      {Object.entries(isPaymentLink ? PAYMENT_NAMES : SOCIAL_NAMES)
        .filter(([l]) => l !== "linkedin" && l !== "workSite")
        .map(([type, displayName], index) => (
          <LinkOutput
            {...{ type, displayName }}
            key={index}
            onClick={() => {
              onSelect(type);
              goBack();
            }}
          />
        ))}
    </Body>
  </Root>
);

export default LinksTypeSelector;
