import styled from "@emotion/styled";
import React from "react";
import Input from "@components/Input";
import { useObserver } from "mobx-react-lite";
import { useUpdateScreenVM } from "@src/screens/UpdateScreen/UpdateScreenVM";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 11px;
  }
`;

const FIO: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const dictionary = useLanguage();
  return useObserver(() => (
    <Root>
      <Input
        value={vm.card.lastName ?? ""}
        placeholder={dictionary.placeholders.lastName}
        onChange={(v) => (vm.card.lastName = v)}
      />
      <Input
        value={vm.card.firstName ?? ""}
        placeholder={dictionary.placeholders.name}
        onChange={(v) => (vm.card.firstName = v)}
      />
      <Input
        value={vm.card.patronymicName ?? ""}
        placeholder={dictionary.placeholders.middleName}
        onChange={(v) => (vm.card.patronymicName = v)}
      />
    </Root>
  ));
};
export default FIO;
