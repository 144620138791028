import React from "react";
import styled from "@emotion/styled";

const Root = styled.div`
  display: flex;
  right: 13px;
  top: 18px;
`;

const RightArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <Root>
    <svg
      {...props}
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.792894 0.792893C1.18342 0.402369 1.81658 0.402369 2.20711 0.792893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L2.20711 11.2071C1.81658 11.5976 1.18342 11.5976 0.792893 11.2071C0.402369 10.8166 0.402369 10.1834 0.792893 9.79289L4.58579 6L0.792894 2.20711C0.402369 1.81658 0.402369 1.18342 0.792894 0.792893Z"
        fill="#393D43"
      />
    </svg>
  </Root>
);

export default RightArrow;
