import styled from "@emotion/styled";
import React, { createRef, useState } from "react";
import { useObserver } from "mobx-react-lite";
import LinkForm from "./LinkForm";
import { ISocialLink } from "@src/models";
import useOutsideClick from "@src/hooks/useOutsideClick";
import { PAYMENT_NAMES, SOCIAL_NAMES } from "@src/assets/socialIconsConfig";
import LinksTypeSelector from "./LinksTypeSelector";
import useLanguage from "@src/hooks/useLanguage";
import { useStores } from "@stores";

interface IProps {
  defaultType?: string;
  defaultValue?: string;
  defaultDisplayName?: string;
  onClose: () => void;
  onSave: (link: ISocialLink) => void;
  creationMode?: boolean;
  isPaymentLink: boolean;
}

const Root = styled.div`
  display: flex;
  padding: 24px 21px;
  justify-content: center;
  position: fixed;
  top: 5%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: #ffffff;
  box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px 16px 0 0;
`;
const Background = styled.div`
  position: fixed;
  z-index: 4;
  background: #ffffff;
  top: -10px;
  bottom: 0;
  left: 0;
  right: 0;
`;

const EditLinkDialog: React.FC<IProps> = ({ onClose, ...rest }) => {
  const [isLinksListOpened, setLinksListOpened] = useState(rest.creationMode);

  const ref = createRef<HTMLDivElement>();
  useOutsideClick(ref, onClose);

  const { settingsStore } = useStores();
  const dictionary = useLanguage();

  const [type, setType] = useState(rest.defaultType ?? "");
  const [value, setValue] = useState(rest.defaultValue ?? "");
  const [displayName, setDisplayName] = useState(rest.defaultDisplayName ?? "");

  const handleSave = (link: ISocialLink) => {
    rest.onSave(link);
    onClose();
  };
  const handleChangeType = (t: string) => {
    setType(t);
  };

  return useObserver(() => (
    <Background>
      <Root ref={ref}>
        {isLinksListOpened ? (
          <LinksTypeSelector
            isPaymentLink={rest.isPaymentLink}
            title={
              rest.creationMode ? dictionary.socialMediaTab.addLink : undefined
            }
            closeDialog={onClose}
            goBack={() => setLinksListOpened(false)}
            onSelect={handleChangeType}
          />
        ) : (
          <LinkForm
            title={`${
              rest.creationMode
                ? dictionary.add
                : dictionary.socialMediaTab.edit
            } ${
              type === "custom"
                ? settingsStore.language === "RU"
                  ? "ссылку"
                  : "link"
                : SOCIAL_NAMES[type] || PAYMENT_NAMES[type]
            }`}
            onSave={handleSave}
            setLinkValue={setValue}
            linkValue={value}
            linkType={type}
            displayName={displayName}
            setDisplayName={setDisplayName}
            closeDialog={onClose}
            onOpenLinkSelector={() => setLinksListOpened(true)}
            isPaymentLink={rest.isPaymentLink}
          />
        )}
      </Root>
    </Background>
  ));
};

export default EditLinkDialog;
