import styled from "@emotion/styled";
import React, { useState } from "react";
import { cardService } from "@services";
import Input from "@components/Input";
import SizedBox from "@components/SizedBox";
import { useUpdateScreenVM } from "@src/screens/UpdateScreen/UpdateScreenVM";
import { Row } from "@components/flex";
import Title from "@components/fontComponents/Title";
import ToggleArrow from "@components/ToggleArrow";
import Subtitle from "@components/fontComponents/Subtitle";
import { Observer } from "mobx-react-lite";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserNameTab: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const [opened, setOpened] = useState(true);
  const [validationProcessing, setValidationProcessing] = useState(false);
  const dictionary = useLanguage();
  const checkUsername = (username: string) => {
    if (username === "") return;
    setValidationProcessing(true);
    if (/[^a-zA-Z0-9_-]/.test(username)) {
      vm.usernameValidationError = dictionary.wrongUsername;
      setValidationProcessing(false);
      return;
    }

    if (username.length >= 20) {
      vm.usernameValidationError = dictionary.cantBeLonger20;
      setValidationProcessing(false);
      return;
    }
    if (username.length > 0 && username.length < 4) {
      vm.usernameValidationError = dictionary.cantBeLessThan4;
      setValidationProcessing(false);
      return;
    }
    cardService
      .checkUsername(username, vm.card.id)
      .then(() => (vm.usernameValidationError = null))
      .catch(() => (vm.usernameValidationError = dictionary.usernameIsTaken))
      .finally(() => setValidationProcessing(false));
  };
  return (
    <Observer>
      {() => (
        <Root>
          <Row
            onClick={() => setOpened(!opened)}
            style={{ marginBottom: 12, cursor: "pointer", userSelect: "none" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Title>Username</Title>
            <ToggleArrow opened={opened} />
          </Row>
          {opened && (
            <>
              <Subtitle>{dictionary.usernameTab.whatIsUserName}</Subtitle>
              <SizedBox height={29} />
              <Input
                value={vm.card.username}
                onChange={(v) => (vm.card.username = v)}
                onBlur={(e) => checkUsername(e.target.value)}
                onFocus={() => (vm.usernameValidationError = null)}
                error={vm.usernameValidationError ?? undefined}
                placeholder="username"
                disabled={validationProcessing}
                tips={dictionary.usernameTab.allowedSymbols}
              />
              <SizedBox height={13} />
            </>
          )}
        </Root>
      )}
    </Observer>
  );
};
export default UserNameTab;
