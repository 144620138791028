import React from "react";
import PhoneAuth from "@src/screens/auth/PhoneAuth/PhoneAuth";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const PhoneRegisterScreen: React.FC<IProps> = () => {
  const { accountStore, cardStore } = useStores();
  const isLoggedIn = useObserver(() => accountStore.isLoggedIn);
  const card = useObserver(() => cardStore.card);
  const dictionary = useLanguage();

  if (isLoggedIn || card.userId)
    return <Redirect to={ROUTES.CARD.replace(":cardId", card.id)} />;
  return (
    <PhoneAuth
      title={dictionary.register}
      subtitle={dictionary.registerToActivate}
      splashSubtitle={dictionary.tagIsNotRegistered}
      splashButton={dictionary.toRegister}
      register
    />
  );
};
export default PhoneRegisterScreen;
