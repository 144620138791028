import { action, observable } from "mobx";
import { RootStore } from "@stores/index";

export default class ErrorStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable message: string = "";

  //var str = 'one:two;three';
  // str.split(':').pop().split(';')[0];
  @action setError(error: string) {
    this.message = error;
  }
}
