import styled from "@emotion/styled";
import React, { useState } from "react";
import { Row } from "@components/flex";
import Title from "@components/fontComponents/Title";
import { ReactComponent as Close } from "@src/assets/Close.svg";
import Subtitle from "@components/fontComponents/Subtitle";
import Input from "@components/Input";
import Button from "@components/Button";
import Layout, { layoutStyle } from "@components/Layout";
import {
  PAYMENT_NAMES,
  PAYMENT_WITH_PHONE,
  SOCIAL_ICONS,
  SOCIAL_NAMES,
  SOCIAL_WITH_USERNAME,
} from "@src/assets/socialIconsConfig";
import { ISocialLink } from "@src/models";
import { isExistsProtocol, validURL } from "@utils";
import useLanguage from "@src/hooks/useLanguage";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  linkType: string;
  linkValue?: string;
  displayName?: string;
  closeDialog: () => void;
  onSave: (link: ISocialLink) => void;
  onOpenLinkSelector: () => void;
  setLinkValue: (v: string) => void;
  setDisplayName?: (v: string) => void;
  title?: string;
  isPaymentLink: boolean;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${layoutStyle}
`;

const FixedBlock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );

  .layout {
    width: 100%;
    margin: 20px 0;
  }
`;

const LinkForm: React.FC<IProps> = ({
  linkType,
  linkValue,
  displayName,
  setDisplayName,
  setLinkValue,
  onOpenLinkSelector,
  closeDialog,
  onSave,
  title,
  ...rest
}) => {
  const [linkError, setLinkError] = useState<string | null>(null);
  const checkLink = () =>
    setLinkError(validURL(linkValue) ? null : dictionary.invalidLink);
  const dictionary = useLanguage();
  const { settingsStore } = useStores();
  const handleSave = () => {
    if (linkValue == null || !validURL(linkValue)) return;
    if (Object.keys(PAYMENT_NAMES).includes(linkType)) {
      onSave({
        value: linkValue,
        type: linkType,
        displayName: displayName,
      });
      return;
    }
    onSave({
      value: !isExistsProtocol(linkValue ?? "")
        ? "https://" + linkValue
        : linkValue,
      type: linkType,
      displayName: displayName,
    });
  };
  return useObserver(() => (
    <Root {...rest}>
      <Row
        style={{ paddingBottom: 23 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Title>{title}</Title>
        <Close onClick={closeDialog} />
      </Row>
      <div style={{ width: "100%" }}>
        <Subtitle style={{ paddingBottom: 23 }}>
          {rest.isPaymentLink
            ? dictionary.moneyTab.chooseMoneyAndAdd
            : dictionary.socialMediaTab.chooseSocial}
        </Subtitle>
        <Input
          placeholder={
            rest.isPaymentLink
              ? PAYMENT_NAMES[linkType]
              : SOCIAL_NAMES[linkType]
          }
          icon={SOCIAL_ICONS[linkType]}
          value={SOCIAL_NAMES[linkType]}
          style={{ marginBottom: 10 }}
          readOnly={true}
        />
        {linkType === "custom" ? (
          <Input
            value={displayName}
            onChange={setDisplayName}
            placeholder={dictionary.linkName}
            style={{ marginBottom: 10 }}
          />
        ) : null}
        <Input
          {...{
            [linkType && SOCIAL_WITH_USERNAME.includes(linkType)
              ? "prefix"
              : "placeholder"]: settingsStore.socialPlaceholders[
              linkType ?? ""
            ],
          }}
          value={linkValue}
          onChange={setLinkValue}
          onFocus={() => setLinkError(null)}
          onBlur={checkLink}
          error={linkError ?? undefined}
          type={linkType}
          tips={settingsStore.socialTips[linkType ?? ""]}
          inputType={
            PAYMENT_WITH_PHONE.includes(linkType) ? "phone" : undefined
          }
        />
        <FixedBlock>
          <Layout className="layout">
            <Button
              disabled={linkError != null || linkType === ""}
              style={{ marginTop: 80 }}
              onClick={handleSave}
            >
              {dictionary.save}
            </Button>
          </Layout>
        </FixedBlock>
      </div>
    </Root>
  ));
};

export default LinkForm;
