import styled from "@emotion/styled";
import React from "react";
import { layoutStyle } from "@components/Layout";
import Header from "@components/Header";
import {
  MarketplaceScreenVMProvider,
  useMarketplaceScreenVM,
} from "./MarketplaceScreenVM";
import { Observer } from "mobx-react-lite";
import Product from "@src/screens/marketplace/MarketplaceScreen/Product";
import { Column, Row } from "@components/flex";
import Title from "@components/fontComponents/Title";
import OrderProductDialog from "@src/screens/marketplace/MarketplaceScreen/OrderProductDialog";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layoutStyle};
  .grid {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Container = styled(Row)`
  flex-wrap: wrap;
  padding-bottom: 24px;
  & > :not(:last-child) {
    margin-right: 10px;
  }
  & > * {
    margin-bottom: 10px;
    & > .avatar {
      max-width: calc(30vw - 11px);
      max-height: calc(30vw - 11px);
    }
  }
`;

const MarketplaceScreen: React.FC = () => (
  <MarketplaceScreenVMProvider>
    <MarketplaceScreenImpl />
  </MarketplaceScreenVMProvider>
);

const MarketplaceScreenImpl: React.FC = () => {
  const vm = useMarketplaceScreenVM();
  return (
    <Observer>
      {() => (
        <Root>
          <Header />
          {vm.selectedProduct == null &&
            Object.entries(vm.categories).map(([title, products], i) => (
              <Column key={i}>
                <Title style={{ padding: "12px 0" }}>{title}</Title>
                <Container>
                  {products
                    .sort((a, b) =>
                      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                    .map((p) => (
                      <Product
                        key={p.id}
                        product={p}
                        onClick={() => (vm.selectedProductId = p.id)}
                      />
                    ))}
                </Container>
              </Column>
            ))}
          {vm.selectedProduct != null && <OrderProductDialog />}
        </Root>
      )}
    </Observer>
  );
};
export default MarketplaceScreen;
