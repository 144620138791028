import styled from "@emotion/styled";
import React from "react";
import arrowTop from "@src/assets/arrowTop.svg";

interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  opened?: boolean;
}

const Root = styled.img<{ opened?: boolean }>`
  transform: rotate(${({ opened }) => (opened ? 0 : 180)}deg);
`;

const ToggleArrow: React.FC<IProps> = ({ ...rest }) => (
  <Root {...rest} src={arrowTop} alt="arrow" />
);
export default ToggleArrow;
