import { RootStore } from "./index";
import { action, computed, observable } from "mobx";
import touchService from "@services/touchService";
import { ROUTES } from "@stores/RouterStore";

const langMap = [
  "касаний",
  "касание",
  "касания",
  "касания",
  "касания",
  "касаний",
  "касаний",
  "касаний",
  "касаний",
  "касаний",
];
export default class TouchesStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable initialized = false;
  @observable count: number | null = null;
  @action sync = async () => {
    const id = this.rootStore.routerStore.getCardId(ROUTES.CARD);
    if (id != null) {
      await touchService.touch({ cardId: id }).catch(() => null);
      this.count = (await touchService.count(id)).count;
    }
    this.initialized = true;
  };

  @computed get touchesString(): string {
    if (this.count == null) return "";
    if (this.rootStore.settingsStore.language === "RU") {
      return `${this.count} ${langMap[+this.count.toString().slice(-1)]}`;
    }
    if (this.count && this.count >= 2) {
    }
    return this.count && this.count >= 2
      ? `${this.count} touches`
      : `${this.count} touch`;
  }
}
