import React, { useState } from "react";
import Modal from "@src/Modal";
import Product from "@src/screens/marketplace/MarketplaceScreen/Product";
import Title from "@components/fontComponents/Title";
import Input from "@components/Input";
import SizedBox from "@components/SizedBox";
import Button from "@components/Button";
import { Observer } from "mobx-react-lite";
import {
  typeDescriptionMap,
  useMarketplaceScreenVM,
} from "@src/screens/marketplace/MarketplaceScreen/MarketplaceScreenVM";
import NumberInput from "@components/NumberInput";
import { Column, Row } from "@components/flex";
import Loading from "@components/Loading";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useAlert } from "react-alert";
import Subtitle from "@components/fontComponents/Subtitle";
import FieldsDescription from "@components/fontComponents/FieldsDescription";

const OrderProductDialog: React.FC = () => {
  const vm = useMarketplaceScreenVM();
  const alert = useAlert();
  const [validation, setValidation] = useState<{
    name?: string;
    phone?: string;
    address?: string;
  }>({});
  const [loading, setLoading] = useState(false);
  const validate = () => {
    const validation = {
      name:
        vm.orderInfo.name == null || vm.orderInfo.name === "" ? "" : undefined,
      phone:
        vm.orderInfo.phone == null || !isValidPhoneNumber(vm.orderInfo.phone!)
          ? ""
          : undefined,
      address:
        vm.orderInfo.address == null || vm.orderInfo.address === ""
          ? ""
          : undefined,
    };
    setValidation(validation);
    if (Object.keys(validation).some((v) => v != null)) {
      alert.error("Пожалуйста, заполните все обязательные поля");
    }
    return validation;
  };
  const handleSubmit = async () => {
    const validation = validate();
    if (Object.entries(validation).some(([_, v]) => v != null)) return;
    setLoading(true);
    try {
      const paymentLink = await vm.createOrder();
      vm.selectedProductId = null;
      if (paymentLink) window.location.href = paymentLink;
    } catch (e: any) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const checkPromocode = async (e: React.FocusEvent) => {
    e.stopPropagation();
    const res = await vm.checkPromocode();
    alert[res.success ? "success" : "error"](res.message);
    if (!res.success) vm.orderInfo.promocode = undefined;
  };

  return (
    <Observer>
      {() => (
        <Modal title="Заказ" onClose={() => (vm.selectedProductId = null)}>
          <div style={{ overflow: "auto", width: "100%" }}>
            <Product
              count={vm.orderInfo.count}
              avatarSize={175}
              product={vm.selectedProduct!}
            />
            <SizedBox height={8} />
            <Subtitle>{vm.selectedProduct!.description}</Subtitle>
            <Subtitle style={{ whiteSpace: "pre-wrap" }}>
              {typeDescriptionMap[vm.selectedProduct!.type]}
            </Subtitle>
            <SizedBox height={8} />
            <Row justifyContent="center">
              <Column>
                <FieldsDescription>Количество:</FieldsDescription>
                <NumberInput
                  value={vm.orderInfo.count}
                  setValue={(count) => (vm.orderInfo.count = +count)}
                />
              </Column>
            </Row>
            <SizedBox height={15} />
            <Title>Укажите ваши данные</Title>
            <SizedBox height={12} />
            <Input
              placeholder="ФИО*"
              value={vm.orderInfo.name}
              onChange={(v) => (vm.orderInfo.name = v)}
              onFocus={() => setValidation({ name: undefined })}
              error={validation.name}
            />
            <Input
              inputType="phone"
              style={{ marginTop: 8 }}
              placeholder="Телефон*"
              value={vm.orderInfo.phone}
              onChange={(v) => (vm.orderInfo.phone = v)}
              onFocus={() => setValidation({ phone: undefined })}
              error={validation.phone}
            />
            <Input
              style={{ marginTop: 8 }}
              placeholder="Адрес*"
              value={vm.orderInfo.address}
              onChange={(v) => (vm.orderInfo.address = v)}
              onFocus={() => setValidation({ address: undefined })}
              error={validation.address}
            />
            <p style={{ color: "#545961", fontSize: 12, marginTop: 6 }}>
              Доставка осуществляется почтой России
              {/*<a*/}
              {/*  rel="noreferrer noopener"*/}
              {/*  target="_blank"*/}
              {/*  href="https://www.cdek.ru/ru/offices"*/}
              {/*>*/}
              {/*  ПВЗ СДЕК*/}
              {/*</a>*/}
            </p>
            <Input
              style={{ marginTop: 12 }}
              placeholder="Промокод"
              value={vm.orderInfo.promocode}
              onChange={(v) => (vm.orderInfo.promocode = v)}
              onBlur={checkPromocode}
            />
            <Button
              style={{ marginTop: 25 }}
              onClick={handleSubmit}
              disabled={Object.entries(validation).some((v) => v == null)}
            >
              {loading ? (
                <Loading
                  color="#ffffff"
                  style={{ maxHeight: 19 }}
                  fontSize="5px"
                />
              ) : (
                "Заказать"
              )}
            </Button>
          </div>
        </Modal>
      )}
    </Observer>
  );
};
export default OrderProductDialog;
