import { RootStore } from "./index";
import { action, computed, observable } from "mobx";
import rus from "@src/assets/RUS.svg";
import eng from "@src/assets/ENG.svg";
import { lng } from "@src/hooks/languages";
import {
  SOCIAL_PLACEHOLDERS_ENG,
  SOCIAL_PLACEHOLDERS_RU,
  SOCIAL_TIPS_EN,
  SOCIAL_TIPS_RU,
} from "@src/assets/socialIconsConfig";

export enum Language {
  EN = "EN",
  RU = "RU",
}

export default class SettingsStore {
  public rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.language = navigator.language.includes("ru")
      ? Language.RU
      : Language.EN;
  }

  @observable language: Language = Language.RU;

  @action.bound toggleLanguage = () => {
    if (this.language === Language.RU) this.language = Language.EN;
    else {
      this.language = Language.RU;
    }
  };

  @computed get dictionary() {
    return lng[this.language];
  }

  @computed get langIcon(): string {
    return this.language === Language.EN ? eng : rus;
  }

  @computed get socialTips(): Record<string, string> {
    return this.language === Language.EN ? SOCIAL_TIPS_EN : SOCIAL_TIPS_RU;
  }
  @computed get socialPlaceholders(): Record<string, string> {
    return this.language === Language.EN
      ? SOCIAL_PLACEHOLDERS_ENG
      : SOCIAL_PLACEHOLDERS_RU;
  }
}
