import apiService from "./apiService";
import { IUser } from "../models";

export default {
  auth(): Promise<IUser> {
    return apiService.makeApiRequest("api/v1/auth/user", {
      method: "POST",
    });
  },
};
