import apiService from "./apiService";
import { ICard, IUser } from "../models";

export type TUpdateCardParams = Omit<ICard, "_id">;

export default {
  card: (id: string): Promise<ICard> =>
    apiService.makeApiRequest(`api/v1/cards/card/${id}`),

  getContactById: (id: string): Promise<any> =>
    apiService.makeApiRequest(`api/v1/cards/contact/${id}`),

  registerCard: (id: string, data?: { phone: string }): Promise<IUser> =>
    apiService.makeApiRequest(
      `api/v1/cards/card/${id}`,
      { method: "POST", data },
      false
    ),

  updateCard: (id: string, data: TUpdateCardParams): Promise<ICard> =>
    apiService.makeApiRequest(
      `api/v1/cards/card/${id}`,
      { method: "PUT", data },
      false
    ),

  setDirect: (data: { direct?: string }): Promise<ICard> =>
    apiService.makeApiRequest(`api/v1/cards/direct`, { method: "POST", data }),

  deleteCard: (id: string): Promise<ICard> =>
    apiService.makeApiRequest(`api/v1/cards/card/${id}`, { method: "DELETE" }),

  getCardForUser: (): Promise<ICard> =>
    apiService.makeApiRequest(`api/v1/cards/my`),

  checkUsername: (username: string, cardId: string): Promise<boolean> =>
    apiService.makeApiRequest(`api/v1/cards/check-username`, {
      method: "POST",
      data: { username, cardId },
    }),

  uploadPhoto(file: Blob | File): Promise<string> {
    const formData = new FormData();
    formData.append("file", file);
    return apiService.makeApiRequest("api/v1/cards/upload-photo", {
      method: "POST",
      data: formData,
      type: "file",
    });
  },
};
