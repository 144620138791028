import React from "react";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import PhoneAuthUnavailable from "@src/screens/auth/PhoneAuth/PhoneAuthUnavailable";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const PhoneLoginScreen: React.FC<IProps> = () => {
  const { accountStore } = useStores();
  const dictionary = useLanguage();
  return useObserver(() => {
    if (accountStore.isLoggedIn) return <Redirect to={ROUTES.ROOT} />;
    return (
      <PhoneAuthUnavailable />
    );
  });
};
export default PhoneLoginScreen;
