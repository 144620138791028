import styled from "@emotion/styled";
import React, { useState } from "react";
import { useStores } from "@stores";
import { ROUTES } from "@stores/RouterStore";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { isValidPhoneNumber } from "react-phone-number-input";
import Header from "@components/Header";
import { layoutStyle } from "@components/Layout";
import SendSmsPhone from "@src/screens/auth/PhoneAuth/SendSmsPhone";
import CodeConfirm from "./CodeConfirm";
import HelpButton from "@components/HelpButton";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  title: string;
  subtitle: string;
  register?: boolean;
  splashButton?: string;
  splashSubtitle?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layoutStyle};
`;

interface ConfirmationResult {
  confirm(verificationCode: string): Promise<any>;

  verificationId: string;
}

const PhoneAuth: React.FC<IProps> = (props) => {
  const alert = useAlert();
  const [phone, setPhone] = useState<string>("+7");
  const [
    confirmationResult,
    setConfirmationResult,
  ] = useState<ConfirmationResult | null>(null);
  const dictionary = useLanguage();
  const { cardStore, accountStore } = useStores();
  const history = useHistory();
  const waitingForCode = confirmationResult != null;
  const processSignIn = async () => {
    if (!isValidPhoneNumber(phone)) return;
    try {
      const confirmationResult = await accountStore
        .signInWithPhoneNumber(phone)
        .catch((e) => {
          console.error(e);
          alert.error(e.message);
        });
      setConfirmationResult(confirmationResult || null);
    } catch (e: any) {
      alert.error((e as any)?.message ?? "something wrong");
    }
  };
  const continueWithCode = async (smsCode: string): Promise<string | null> => {
    if (smsCode.length !== 6) {
      return dictionary.codeShouldContain6Numbers;
    }
    try {
      const result = await confirmationResult?.confirm(smsCode);
      props.register &&
        (await cardStore.registerCard({
          phone: result.user.phoneNumber,
        }));
      accountStore.user = result.user;
      if (accountStore.user) {
        accountStore.user.firebase_uid = result.user.uid;
      }
      if (accountStore.user && !accountStore.user.isAnonymous) {
        history.push(ROUTES.EDIT);
      }
      return null;
    } catch (e: any) {
      return dictionary.wrongSmsCode;
    }
  };

  return (
    <Root>
      <Header style={{ marginBottom: 82 }} />
      {!waitingForCode ? (
        <SendSmsPhone
          phone={phone}
          setPhone={setPhone}
          processSignIn={processSignIn}
          title={props.title}
          subtitle={props.subtitle}
        />
      ) : (
        <CodeConfirm
          resetPhone={() => setConfirmationResult(null)}
          continueWithCode={continueWithCode}
          phoneNumber={phone}
          sendCodeAgain={processSignIn}
        />
      )}
      <HelpButton />
    </Root>
  );
};
export default PhoneAuth;
