import React from "react";
import styled from "@emotion/styled";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Row } from "@components/flex";
import { ISocialLink } from "@src/models";
import { inputStyle } from "@components/Input";
import dragIcon from "@src/assets/dragIcon.svg";
import {
  PAYMENT_NAMES,
  SOCIAL_ICONS,
  SOCIAL_NAMES,
} from "@src/assets/socialIconsConfig";
import trash from "@src/assets/trash.svg";
import { useObserver } from "mobx-react-lite";

const reorder = (list: ISocialLink[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const QuoteItem = styled.div`
  ${inputStyle};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  position: relative;
`;

interface IQuoteProps {
  index: number;
  quote: ISocialLink;
  onClick: () => void;
  onDelete: () => void;
}

const TextOutput = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const dragIconStyle: React.CSSProperties = {
  height: 8,
  position: "absolute",
  left: -10,
};

const Quote: React.FC<IQuoteProps> = ({ quote, index, onClick, onDelete }) => {
  return (
    <Row>
      <Draggable draggableId={String(index)} index={index}>
        {(provided) => (
          <QuoteItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <img style={dragIconStyle} src={dragIcon} alt="drag" />
            <img
              style={{ marginRight: 8, width: 26 }}
              src={quote.type && SOCIAL_ICONS[quote.type]}
              alt="icon"
            />
            <TextOutput onClick={onClick}>
              {quote.displayName != null && quote.displayName !== ""
                ? quote.displayName
                : SOCIAL_NAMES[quote.type] || PAYMENT_NAMES[quote.type]}
            </TextOutput>
            <img
              style={{ width: 17.42 }}
              src={trash}
              alt="trash"
              onClick={onDelete}
            />
          </QuoteItem>
        )}
      </Draggable>
    </Row>
  );
};

type IProps = {
  links: ISocialLink[];
  onChange: (links: ISocialLink[]) => void;
  onDelete: (index: number) => void;
  onStartEdit: (value: { link: ISocialLink; index?: number }) => void;
};

const SocialLinksList: React.FC<IProps> = ({
  links,
  onChange,
  onStartEdit,
  onDelete,
}) => {
  function onDragEnd(result: any) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    onChange(reorder(links, result.source.index, result.destination.index));
  }

  const QuoteList = React.memo(function QuoteList({ quotes }: any) {
    return useObserver(() =>
      quotes.map((quote: ISocialLink, index: number) => (
        <Quote
          onClick={() => onStartEdit({ link: quote, index })}
          onDelete={() => onDelete(index)}
          quote={quote}
          index={index}
          key={index}
        />
      ))
    );
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <QuoteList quotes={links} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SocialLinksList;
