import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/tooltipStyle.css";
import "react-phone-number-input/style.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { RootStore, storesContext } from "@stores";
import "normalize.css";
import * as reactAlert from "react-alert";
import AlertTemplate from "@components/AlertTemplate";

const mobxStore = new RootStore();

const options = {
  timeout: 5000,
  position: reactAlert.positions.TOP_RIGHT,
};

ReactDOM.render(
  <React.StrictMode>
    <storesContext.Provider value={mobxStore}>
      <Router history={mobxStore.routerStore.history}>
        <reactAlert.Provider template={AlertTemplate} {...options}>
          <App />
        </reactAlert.Provider>
      </Router>
    </storesContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
