import React from "react";
import ResultScreen, {
  RESULT_SCREEN_TYPE,
} from "@src/screens/result/ResultScreen";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@src/stores/RouterStore";

interface IProps {}

const SucceededPayment: React.FC<IProps> = () => {
  const history = useHistory();
  return (
    <ResultScreen
      title="Уху! Оплата прошла успешно"
      subtitle="Скоро ваш заказ будет у Вас"
      type={RESULT_SCREEN_TYPE.SUCCESS}
      onButtonClick={() => history.push(ROUTES.ROOT)}
    />
  );
};
export default SucceededPayment;
//todo add order details and etc
