import tiktok from "@src/assets/TikTok.svg";
import discord from "@src/assets/Discord.svg";
import facebook from "@src/assets/Facebook.svg";
import dribbble from "@src/assets/Dribbble.svg";
import figma from "@src/assets/Figma.svg";
import globe from "@src/assets/Globe.svg";
import pinterest from "@src/assets/Pinterest.svg";
import reddit from "@src/assets/Reddit.svg";
import snapchat from "@src/assets/Snapchat.svg";
import telegram from "@src/assets/Telegram.svg";
import twitter from "@src/assets/Twitter.svg";
import tumblr from "@src/assets/Tumblr.svg";
import youtube from "@src/assets/YouTube.svg";
import vimeo from "@src/assets/vimeo.svg";
import whatsapp from "@src/assets/whatsapp.svg";
import instagram from "@src/assets/Instagram.svg";
import linkedin from "@src/assets/LinkedIn.svg";
import applemusic from "@src/assets/Applen music.svg";
import behance from "@src/assets/Behance.svg";
import messenger from "@src/assets/Messenger.svg";
import odnoklassniki from "@src/assets/OK.svg";
import slack from "@src/assets/Slack.svg";
import soundcloud from "@src/assets/Soundcloud.svg";
import spotify from "@src/assets/Spotify.svg";
import vk from "@src/assets/VK.svg";
import qr from "@src/assets/qr.png";
import tinkoff from "@src/assets/Tinkof.svg";
import sberbank from "@src/assets/sber-green.png";
import chain from "@src/assets/chain.svg";
import alfabank from "@src/assets/alfa.png";
import yoomoney from "@src/assets/yoomoney.png";
import qiwi from "@src/assets/qiwi.png";
import payPal from "@src/assets/paypal.png";
import wavesExchange from "@src/assets/waves-exchange.svg";
import sberTips from "@src/assets/sberTips.svg";
import hh from "@src/assets/hh.svg";

export const SOCIAL_ICONS: Record<string, string> = {
  custom: globe,
  tiktok: tiktok,
  discord: discord,
  facebook: facebook,
  dribbble: dribbble,
  figma: figma,
  pinterest: pinterest,
  reddit: reddit,
  snapchat: snapchat,
  telegram: telegram,
  twitter: twitter,
  tumblr: tumblr,
  youtube: youtube,
  vimeo: vimeo,
  whatsapp: whatsapp,
  instagram: instagram,
  linkedin: linkedin,
  applemusic: applemusic,
  behance: behance,
  messenger: messenger,
  odnoklassniki: odnoklassniki,
  slack: slack,
  soundcloud: soundcloud,
  spotify: spotify,
  vk: vk,
  workSite: globe,
  qr: qr,
  chain: chain,
  tinkoff: tinkoff,
  sberbank: sberbank,
  alfabank: alfabank,
  yoomoney: yoomoney,
  vkPay: vk,
  qiwi: qiwi,
  payPal: payPal,
  wavesExchange: wavesExchange,
  sberTips: sberTips,
  hh: hh,
};
export const SOCIAL_NAMES: Record<string, string> = {
  custom: "Custom",
  telegram: "Telegram",
  whatsapp: "WhatsApp",
  instagram: "Instagram",
  youtube: "YouTube",
  tiktok: "TikTok",
  discord: "Discord",
  vk: "VK",
  facebook: "Facebook",
  spotify: "Spotify",
  dribbble: "Dribbble",
  twitter: "Twitter",
  figma: "Figma",
  pinterest: "Pinterest",
  reddit: "Reddit",
  snapchat: "Snapchat",
  tumblr: "Tumblr",
  vimeo: "Vimeo",
  applemusic: "AppleMusic",
  behance: "Behance",
  messenger: "Messenger",
  odnoklassniki: "OK",
  slack: "Slack",
  soundcloud: "SoundCloud",
  linkedin: "LinkedIn",
  workSite: "Work Site",
  hh: "Head Hunter",
};
export const PAYMENT_NAMES: Record<string, string> = {
  tinkoff: "Tinkoff",
  sberbank: "Сбербанк",
  alfabank: "Альфабанк",
  yoomoney: "YooMoney",
  sberTips: "Сбер Чаевые",
  qiwi: "Qiwi",
  payPal: "PayPal",
};

export const SOCIAL_WITH_USERNAME = [
  "tiktok",
  "discord",
  "facebook",
  "pinterest",
  "telegram",
  "whatsapp",
  "instagram",
  "linkedin",
  "vk",
  "behance",
  "twitter",
  "dribbble",
  "tumblr",
  "vimeo",
  "snapchat",
  "odnoklassniki",
  "messenger",
  "reddit",
  "vkPay",
  "payPal",
  "yoomoney",
];
export const PAYMENT_WITH_PHONE = ["qiwi", "sberbank"];

export const SOCIAL_PLACEHOLDERS_RU: Record<string, string> = {
  custom: "Ссылка",
  workSite: "Ссылка",
  tiktok: "tiktok.com/@",
  discord: "discord.gg/",
  facebook: "facebook.com/",
  dribbble: "dribbble.com/",
  figma: "https://www.figma.com/file/",
  pinterest: "pinterest.com/username/",
  snapchat: "snapchat.com/add/",
  telegram: "t.me/",
  twitter: "twitter.com/",
  tumblr: "tumblr.com/blog/",
  youtube: "YouTube",
  vimeo: "vimeo.com/",
  whatsapp: "wa.me/",
  instagram: "instagram.com/",
  linkedin: "linkedin.com/in/",
  behance: "www.behance.net/",
  vk: "vk.com/",
  slack: "Slack",
  spotify: "Spotify",
  applemusic: "AppleMusic",
  odnoklassniki: "ok.ru/profile/",
  messenger: "m.me/",
  soundcloud: "SoundCloud",
  reddit: "reddit.com/user/",
  tinkoff: "Ссылка",
  sberbank: "Номер телефона",
  alfabank: "Ссылка",
  payPal: "https://paypal.me/",
  vkPay: "https://vk.pay/",
  yoomoney: "https://yoomoney.ru/to/",
  wavesExchange: "Ccылка",
  qiwi: "Номер телефона",
  hh: "Введите ссылку на свое резюме",
};
export const SOCIAL_PLACEHOLDERS_ENG: Record<string, string> = {
  custom: "Link",
  workSite: "Link",
  tiktok: "tiktok.com/@",
  discord: "discord.gg/",
  facebook: "facebook.com/",
  dribbble: "dribbble.com/",
  figma: "https://www.figma.com/file/",
  pinterest: "pinterest.com/username/",
  snapchat: "snapchat.com/add/",
  telegram: "t.me/",
  twitter: "twitter.com/",
  tumblr: "tumblr.com/blog/",
  youtube: "YouTube",
  vimeo: "vimeo.com/",
  whatsapp: "wa.me/",
  instagram: "instagram.com/",
  linkedin: "linkedin.com/in/",
  behance: "www.behance.net/",
  vk: "vk.com/",
  slack: "Slack",
  spotify: "Spotify",
  applemusic: "AppleMusic",
  odnoklassniki: "ok.ru/profile/",
  messenger: "m.me/",
  soundcloud: "SoundCloud",
  reddit: "reddit.com/user/",
  tinkoff: "Link",
  sberbank: "Phone number",
  alfabank: "Link",
  payPal: "https://paypal.me/",
  vkPay: "https://vk.pay/",
  yoomoney: "https://yoomoney.ru/to/",
  wavesExchange: "Link",
  qiwi: "Phone number",
  hh: "Link to your CV",
};

export const SOCIAL_TIPS_RU: Record<string, string> = {
  custom: "Это может быть любая ссылка",
  workSite: "Ссылка на Ваш рабочий сайт",
  discord: "Введите ссылку на ваш сервер",
  tiktok: "Введите свой username",
  facebook: "Введите свой username",
  dribbble: "Введите свой username",
  snapchat: "Введите свой username",
  vimeo: "Введите свой username",
  twitter: "Введите свой username",
  instagram: "Введите свой username",
  behance: "Введите свой username",
  messenger: "Введите свой username",
  linkedin: "Введите свой username или user id ",
  vk: "Введите свой username или user id",
  odnoklassniki: "Введите свой userId",
  telegram:
    "Введите свой username. Вы сможете его найти в приложении telegram в настройках профиля",
  figma: "Поделитесь ссылкой на ваш проект",
  pinterest: "Вставьте ссылку на ваш аккаун или же доску, что Вам нравится",
  reddit: "Введите свой username",
  tumblr: "Введите название своего блога",
  youtube: "Введите ссылку на свой канал или же видео, что Вам нравится",
  whatsapp: "Введите номер телефона, который привязан к приложению Whats App",
  slack: "Это может быть ваша рабочая почта связанная с проектом",
  applemusic:
    "Поделитесь ссылкой на ваш профиль или же на плейлист, что Вам нравится",
  soundcloud:
    "Поделитесь ссылкой на ваш профиль или же на плейлист, что Вам нравится",
  spotify:
    "Поделитесь ссылкой на ваш профиль или же на плейлист, что Вам нравится",
  tinkoff:
    "Скопируйте ссылку на оплату в своем приложениие Tinkoff. Счет Тинькофф  - Реквизиты - Ссылка для пополнения",
  sberbank: "Введите номер телефона, который привязан к счету в Сбербанке",
  alfabank:
    "Выберите нужную карту для пополнения в приложении Альфа-Банк, затем нажмите на пополения и выберите поле 'С карты другого банка' и 'Запросить пперевод'. Используйте эту ссылку",
  payPal: "Введите свой username",
  vkPay: "Введите id своей страницы",
  yoomoney: "Введите номер кошелька",
  qiwi: "Введите номер телефона к которому привязан счет",
  wavesExchange: "Введите ссылку для оплаты",
  sberTips:
    "Введите ссылку на свои Сбер Чаевые. Их зарегистрировать можно тут - www.mysbertips.ru",
};
export const SOCIAL_TIPS_EN: Record<string, string> = {
  custom: "It can be any kind of link",
  workSite: "Link to your work site",
  discord: "Add link to your server",
  tiktok: "Enter your username",
  facebook: "Enter your username",
  dribbble: "Enter your username",
  snapchat: "Enter your username",
  vimeo: "Enter your username",
  twitter: "Enter your username",
  instagram: "Enter your username",
  behance: "Enter your username",
  messenger: "Enter your username",
  linkedin: "Enter your username or user id ",
  vk: "Enter your username or user id",
  odnoklassniki: "Enter your userId",
  telegram:
    "Enter your username. You can find it in profile settings of Telegram application",
  figma: "Share link to your project",
  pinterest: "Enter link of your account or of board that you like",
  reddit: "Enter your username",
  tumblr: "Enter name of your blog",
  youtube: "Enter link of your account or channel that you like",
  whatsapp: "Enter phone number that related to your Whats App",
  slack: "It can be work email related to project",
  applemusic: "Share link to your account or playlist that you like",
  soundcloud: "Share link to your account or playlist that you like",
  spotify: "Share link to your account or playlist that you like",
  tinkoff: "Copy link for money transfer in Tinkoff application.",
  sberbank: "Phone number related to bank account in Sberbank",
  alfabank: "Copy link for money transfer in Alfa Bank application.",
  payPal: "Enter your username",
  vkPay: "Введите id своей страницы",
  yoomoney: "Enter wallet number",
  qiwi: "Enter phone number related to account",
  sberTips:
    "Enter link to Сбер Чаевые. You can register it here - www.mysbertips.ru",
};
