import apiService from "./apiService";
import { ICreateOrderInfo, IProduct, IPromocode } from "@src/models";

export default {
  products: (): Promise<IProduct[]> =>
    apiService.makeApiRequest(`api/v1/store/products`),

  createOrder: (
    data: ICreateOrderInfo
  ): Promise<{ paymentLink: string | null }> =>
    apiService.makeApiRequest(`api/v1/orders/purchase`, {
      method: "POST",
      data,
    }),
  checkPromocode: (data: { name: string }): Promise<IPromocode> =>
    apiService.makeApiRequest(`api/v1/store/check_promocode`, {
      method: "POST",
      data,
    }),
};
