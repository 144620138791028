import React, { useMemo } from "react";
import { useVM } from "@src/hooks/useVM";
import { action, computed, observable, runInAction, when } from "mobx";
import { RootStore, useStores } from "@stores";
import { ROUTES } from "@stores/RouterStore";
import { cardService } from "@services";
import { ICard } from "@src/models";

const ctx = React.createContext<UpdateScreenVM | null>(null);

export const UpdateScreenVMProvider: React.FC = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(() => new UpdateScreenVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useUpdateScreenVM = () => useVM(ctx);

class UpdateScreenVM {
  constructor(private rootStore: RootStore) {
    when(() => rootStore.accountStore.initialized, this.init);
  }

  @observable photoLoading = false;
  @observable usernameValidationError: string | null = null;

  @computed get validUsername() {
    return this.usernameValidationError == null;
  }

  @computed get saveDisabled() {
    return !this.validUsername || this.photoLoading;
  }

  @observable initialized = false;
  @observable card: ICard = {} as ICard;

  @action
  init = async () => {
    this.initialized = false;
    try {
      this.card = await cardService.getCardForUser();
      runInAction(() => {
        if (this.card.username == null) this.card.username = "";
        if (this.card.phones == null) this.card.phones = [];
        if (this.card.emails == null) this.card.emails = [];
        if (this.card.socialLinks == null) this.card.socialLinks = [];
        if (this.card.payments == null) this.card.payments = [];
      });
    } catch (e: any) {
      this.rootStore.errorStore.setError(e.message);
      this.rootStore.routerStore.history.push(ROUTES.OOPS);
    } finally {
      this.initialized = true;
    }
  };

  updateCard = async () => {
    const filteredCard = this.card;
    filteredCard.emails = filteredCard.emails?.filter((email) => email);
    filteredCard.phones = filteredCard.phones?.filter((phone) => phone);
    filteredCard.socialLinks = filteredCard.socialLinks?.filter(
      (link) => link.value !== ""
    );
    if (this.card.workPhone == null) filteredCard.workPhone = "";
    await cardService.updateCard(this.card.id, filteredCard);
    try {
      await this.rootStore.cardStore.getCardById(this.card.id);
    } catch (e: any) {
      this.rootStore.errorStore.setError(e.message);
      this.rootStore.routerStore.history.push(ROUTES.OOPS);
    }
    this.rootStore.routerStore.history.push(
      ROUTES.CARD.replace(
        ":cardId",
        filteredCard.username ? filteredCard.username : this.card.id
      )
    );
    this.rootStore.touchesStore.sync();
  };
  deleteAccount = async () => {
    try {
      const cardId = this.card.id;
      await cardService.deleteCard(cardId);
      await this.rootStore.accountStore.signOut();
      this.rootStore.routerStore.history.push(
        ROUTES.REGISTER.replace(":cardId", cardId)
      );
      window.location.reload();
    } catch (e: any) {
      this.rootStore.errorStore.setError(e.message);
      this.rootStore.routerStore.history.push(ROUTES.ROOT);
    }
  };
}
